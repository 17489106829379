<template>
    <footer class="app-footer z-30" :class="footerClasses">
        <LayoutContained>
            <div class="py-6 text-center text-gray-100 lg:text-start">
                <div>
                    <NuxtLink class="hover:underline" to="/">Home</NuxtLink>
                    | <NuxtLink class="hover:underline" to="/donations">Donations</NuxtLink>
                    | <NuxtLink class="hover:underline" to="/signature-products">Signature Products</NuxtLink>
                    | <NuxtLink class="hover:underline" to="/andy-guitar-app">Mobile App</NuxtLink>
                    | <NuxtLink class="hover:underline" to="/terms">Terms</NuxtLink>
                    | <NuxtLink class="hover:underline" to="/cookies">Cookies</NuxtLink>
                    | <NuxtLink class="hover:underline" to="/privacy">Privacy</NuxtLink>
                    <span v-if="$features.featuresLinkInFooter()"> | <NuxtLink class="hover:underline" to="/features">Features</NuxtLink></span>
                </div>
                <div class="mt-1">
                    &copy; {{ new Date().getFullYear() }}
                    {{ $config.public.companyNameLong }}
                </div>
            </div>
        </LayoutContained>
    </footer>
</template>

<script>
import { mapState } from 'pinia';

export default {
    name: 'AppFooter',
    computed: {
        ...mapState(useStoreAuth, {
            isLoggedIn: 'isLoggedIn',
        }),
        footerColor () {
            if (['index', 'membership', 'signature-products', 'live', 'live-streamSlug'].includes(this.$route.name)) return 'bg-zinc-900';
            return 'bg-sky-800';
        },
        footerPadding () {
            if (this.isLoggedIn && ['index', 'courses', 'videos', 'live', 'live-streamSlug'].includes(this.$route.name)) return 'pb-16 lg:pb-0';
            return 'pb-0';
        },
        footerClasses () {
            const cls = {};
            cls[this.footerColor] = true;
            cls[this.footerPadding] = true;
            return cls;
        },
    },
};
</script>
